import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/apps/afa-members/app/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/afa-members/app/components/Header/logoutButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/afa-members/app/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/afa-members/public/images/icons/social/facebook-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/afa-members/public/images/icons/social/instagram-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/afa-members/public/images/icons/social/pinterest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/afa-members/public/images/icons/social/twitter-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/afa-members/public/images/logo-i.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/afa-members/public/images/logo.png");
;
import(/* webpackMode: "eager", webpackExports: ["NavBase"] */ "/vercel/path0/libs/modules/src/shared/Nav/NavBase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/FooterNavItem/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/NavBar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/NavList/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/ui/src/nav/NavItem/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/nav/NavSubmenuItem/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Karla\",\"arguments\":[{\"subsets\":[\"latin\"],\"style\":[\"normal\",\"italic\"],\"weight\":[\"200\",\"300\",\"500\",\"400\",\"600\",\"700\",\"800\"],\"display\":\"swap\"}],\"variableName\":\"karla\"}");

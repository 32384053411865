'use client';

import type { FC } from 'react';
import { signOut, useSession } from 'next-auth/react';

const LogoutButton: FC = () => {
  const { data: session } = useSession();

  return (
    session && (
      <>
        <button
          onClick={() => signOut()}
          className="bg-red-500 text-white font-bold px-6 py-2 mt-3"
        >
          Log Out
        </button>
      </>
    )
  );
};

export default LogoutButton;
